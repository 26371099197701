<template>
  <div class="know-box">
    <!-- 页面元素 -->
    <div class="know-content-box">
      <!-- 标题1 -->
      <div class="u-flex u-col-stretch know-title">
        <div class="u-flex-1 u-flex-col u-row-between know-title-font">
          <div class="font-tit">01.如何在吉用钱包获得贷款？</div>
          <div class="font-ntit">
            仅需3步即可在吉用钱包轻松获得最高200,000元贷款
          </div>
        </div>
      </div>
      <!-- 内容1 -->
      <div class="know-cont know-cont1-box">
        <!-- 在首页点击提升额度 -->
        <div class="u-flex u-col-top know-cont1">
          <div class="know-cont-tit">①</div>
          <div class="know-cont1-right">
            <div class="know-cont-tit">在首页点击提升额度</div>
            <div class="know-cont1-cont">
              首页点击【提升额度】按钮进入信息采集页面，按照页面中的引导依次如实完成资料填写，这一步大概率会让你使用2分钟的时间进行。完成后你有机会获得2张提额券。
            </div>
          </div>
        </div>
        <!-- 完成资料提交 -->
        <div class="u-flex u-col-top know-cont1">
          <div class="know-cont-tit">②</div>
          <div class="know-cont1-right">
            <div class="know-cont-tit">完成资料提交</div>
            <div class="know-cont1-cont">
              信息采集完成后点击页面中【下一步】按钮提交您的贷款申请，您的贷款申请预计再5分钟内可以完成审核，在此期间建议您开启通知权限，及时获知审核结果。
            </div>
          </div>
        </div>
        <!-- 完成提款 -->
        <div class="u-flex u-col-top know-cont1">
          <div class="know-cont-tit">③</div>
          <div class="know-cont1-right">
            <div class="know-cont-tit">完成提款</div>
            <div class="know-cont1-cont">
              额度审核完成后，在首页点击【提款】按钮，绑定您的银行卡，配合安全方面考虑，需要你完成人脸识别，操作完成后，资金将在10分钟内打款到您的指定账户。
            </div>
          </div>
        </div>
      </div>
      <!-- 标题2 -->
      <div class="u-flex u-col-stretch know-title">
        <div class="u-flex-1 u-flex-col u-row-between know-title-font">
          <div class="font-tit">02.如何还款到吉用钱包？</div>
        </div>
      </div>
      <!-- 内容2 -->
      <div class="know-cont know-cont2-box">
        <div class="u-flex u-col-center">
          <img
            class="know-cont2-img"
            src="../assets/images/know_2_1.png"
            alt=""
          />
          <div class="know-cont2-tit">方式一：手动还款</div>
        </div>
        <div class="know-cont2-cont">
          您贷款提取完成后，首页会为您生成还款计划，您可以按照还款计划中约定的时间，按照页面指引操作还款，为了避免逾期导致征信不良，建议您开启通知获得还款信息。
        </div>
      </div>
      <!-- 内容2 -->
      <div class="know-cont know-cont2-box">
        <div class="u-flex u-col-center">
          <img
            class="know-cont2-img"
            src="../assets/images/know_2_2.png"
            alt=""
          />
          <div class="know-cont2-tit">方式二：自动还款</div>
        </div>
        <div class="know-cont2-cont">
          在您的贷款申请审核通过后，绑定银行卡进行提款操作时，勾选页面中【同步绑定为还款卡】按钮，同时勾选【三方代扣协议】，我们将为您开通自动还款功能，会根据还款计划自动从绑定的银行卡中扣款，为了避免扣款失败导致的逾期问题，请您在绑定的银行卡中预留足够的资金。
        </div>
      </div>
      <!-- 标题3 -->
      <div class="u-flex u-col-stretch know-title">
        <div class="u-flex-1 u-flex-col u-row-between know-title-font">
          <div class="font-tit">03.如何提升吉用钱包的额度？</div>
        </div>
      </div>
      <!-- 内容3 -->
      <div class="u-flex u-row-between know-cont3-box">
        <div class="u-flex-col u-col-center">
          <img
            class="know-cont3-img"
            src="../assets/images/know_3_1.png"
            alt=""
          />
          <div class="know-cont3-tit">· 提交补充信息 ·</div>
          <div class="know-cont3-ntit">
            上传证件照 (仅需2分钟)<br />其他补充信息 (仅需1分钟)
          </div>
        </div>
        <div class="u-flex-col u-col-center">
          <img
            class="know-cont3-img"
            src="../assets/images/know_3_2.png"
            alt=""
          />
          <div class="know-cont3-tit">· 保持良好的还款记录 ·</div>
          <div class="know-cont3-ntit">
            获得贷款后定时还款<br />多借几次，好借好还
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Know",
};
</script>

<style lang="scss" scoped>
.know-box {
  background: linear-gradient(
    180deg,
    rgba(43, 90, 249, 0.2) 1rem,
    rgba(241, 242, 246, 0) 4rem
  );
  background-color: #f1f2f6;
  // 有按钮时的padding
  // padding: 0.6rem 0.4rem 2.88rem;
  // 没有按钮时的padding
  padding: 0.6rem 0.4rem;

  .know-content-box {
    padding: 0.4rem 0.3rem 0.6rem;
    background: #fff;
    border-radius: 0.24rem;

    .know-title {
      margin-bottom: 0.4rem;

      &::before {
        display: block;
        content: "";
        width: 2px;
        background: #3563fa;
      }

      .know-title-font {
        margin-left: 0.2rem;
      }

      .font-tit {
        font-size: 0.28rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #3563fa;
        line-height: 0.3rem;
      }

      .font-ntit {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #868e9e;
        line-height: 0.3rem;
        margin-top: 0.06rem;
      }
    }

    .know-cont {
      padding: 0.36rem 0.24rem 0.4rem;
      background: rgba(53, 99, 250, 0.05);
      border-radius: 0.12rem;

      .know-cont-tit {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #111c31;
        line-height: 0.34rem;
      }
    }

    .know-cont1-box {
      margin-bottom: 0.6rem;
      .know-cont1 {
        margin-bottom: 0.48rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .know-cont1-right {
        margin-left: 0.16rem;
      }

      .know-cont1-cont {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: justify;
        color: #868e9e;
        line-height: 0.36rem;
        margin-top: 0.16rem;
      }
    }

    .know-cont2-box {
      margin-bottom: 0.24rem;

      & + .know-cont2-box {
        margin-bottom: 0.6rem;
      }
      .know-cont2-img {
        display: block;
        width: 0.48rem;
        height: 0.48rem;
        margin-right: 0.16rem;
      }

      .know-cont2-tit {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #111c31;
        line-height: 0.34rem;
      }

      .know-cont2-cont {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: justify;
        color: #868e9e;
        line-height: 0.36rem;
        margin-top: 0.2rem;
      }
    }

    .know-cont3-box {
      .know-cont3-img {
        display: block;
        width: 1.44rem;
        height: 1.44rem;
        margin-bottom: 0.24rem;
      }

      .know-cont3-tit {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #111c31;
        line-height: 0.34rem;
        margin-bottom: 0.14rem;
      }

      .know-cont3-ntit {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: justify;
        color: #868e9e;
        line-height: 0.36rem;
      }
    }
  }
}
</style>
